@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&display=swap');
@import '../scss/variables';

// **** Prime React CSS **** //
@import 'primereact/resources/themes/lara-light-indigo/theme.css';
@import 'primereact/resources/primereact.min.css';
@import 'primeicons/primeicons.css';
@import 'primeflex/primeflex.scss';

// **** Global CSS **** //

body {
    margin: 0px;
    padding: 0px;
    font-family: $font-base;
}

a {
    text-decoration: none;
    &:hover {
        text-decoration: none;
    }
}
.error-message {
    color: #f00;
    font-size: 12px;
    font-weight: 400;
    position: relative;
    top: 5px;
}

// Overriding password toogle mask icon
.p-password {
    .pi-eye{
        &::before {
            content: "\e965" !important;
        }
    }
    .pi-eye-slash{
        &::before {
            content: "\e966" !important;
        }
    }
}

// Link visited color
a.underline, a.underline:active, a.underline:hover {
    color: #33409C;
}

.p-toast{
    opacity: 1;
    max-width: 26rem;
    .p-toast-message{
        &.p-toast-message-success{
            background:$white;
            color: $black;
            border: none;
            position: relative;
            &::before{
                content: "";
                width: 4px;
                height: 80%;
                position: absolute;
                left: 8px;
                top: 8px;
                background: #2BA079;
                border-radius: 2px;
            }
        }
        &.p-toast-message-error{
            background:$white;
            color: $black;
            border: none;
            position: relative;
            &::before{
                content: "";
                width: 4px;
                height: 80%;
                position: absolute;
                left: 8px;
                top: 8px;
                background: #ff5757;
                border-radius: 2px;
            }
        }
        &.p-toast-message-warn{
            background:$white;
            color: $black;
            border: none;
            position: relative;
            &::before{
                content: "";
                width: 4px;
                height: 80%;
                position: absolute;
                left: 8px;
                top: 8px;
                background: #cc8925;
                border-radius: 2px;
            }
        }
        &.p-toast-message-info{
            background:$white;
            color: $black;
            border: none;
            position: relative;
            &::before{
                content: "";
                width: 4px;
                height: 80%;
                position: absolute;
                left: 8px;
                top: 8px;
                background: #5774cd;
                border-radius: 2px;
            }
            .p-toast-message-icon{
                background: #5774cd !important;
            }
        }
        .p-toast-message-content{
            padding: 12px;
            padding-left: 25px;
            align-items: center;
            .p-toast-message-icon{
                width: 25px;
                height: 25px;
                border-radius: 30px;
                background:#2BA079;
                color: $white;
                font-size: 14px;
                text-align: center;
                line-height: 26px;
                min-width: 25px;
                &.pi-times{
                    background: #ff5757;
                }
                &.pi-exclamation-triangle{
                    background: #cc8925;
                }
            }
            .p-toast-message-text{
                .p-toast-summary{
                    font-size: 14px;
                    font-weight: 500;
                }
                .p-toast-detail{
                    font-size: 15px;
                    margin: 0.4rem 0 0 0;
                    color: #505050;

                    &:first-letter {
                        text-transform: uppercase;
                    }
                }
            }
        }
        .p-toast-icon-close{
            width: auto;
            height: auto;
            color: #505050 !important;
            font-size: 14px;
            position: absolute;
            top: 10px;
            right: 10px;
            z-index: 9;
            .p-toast-icon-close-icon{
                font-size: 13px;
            }
        }
    }
}

// Phone number in signup screen
.custom-phoneno-field {
    width: 100%;
    .react-tel-input{
        .form-control {
            height: 45px;
            border: 1px solid $blue-light;
            width: 100% !important;
        }
    }
    .flag-dropdown{
        border: 1px solid $blue-light !important;
        background-color: $white !important;
        border-radius: 6px 0 0 6px !important;
    }
}
